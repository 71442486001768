<template>
	<!-- BEGIN login -->
	<div class="login login-v1">
		<!-- BEGIN login-container -->
		<div class="login-container">
			<!-- BEGIN login-header -->
			<div class="login-header">
        <div class="brand">
          <div class="d-flex flex-column bd-highlight mb-10 ml-5">
            <div class="p-1 bd-highlight">
                <span>
                  <img
                      :src="ImageLogo"
                      height="100"
                      width="150"
                      alt=""
                  />
                </span>
            </div>
            <div class="bd-highlight">
              <b>Multi</b> Impressões
              <small>high technology paper printing</small>
            </div>
          </div>
        </div>
				<div class="icon">
					<i class="fa fa-lock"></i>
				</div>
			</div>
			<!-- END login-header -->
			
			<!-- BEGIN login-body -->
			<div class="login-body">
				<!-- BEGIN login-content -->
				<div class="login-content fs-13px">
					<form @submit.prevent="checkForm">
						<h4>Informe uma senha nova para prosseguir!</h4>
						<div class="form-floating mb-20px">
							<input v-model="senha" type="password" class="form-control fs-13px h-45px" id="password" placeholder="Password" />
							<label for="password" class="d-flex align-items-center py-0">Senha</label>
						</div>
						<div class="login-buttons">
							<button
                  type="submit"
                  :disabled="saving"
                  class="btn h-45px btn-danger d-flex align-items-center justify-content-center  w-100 btn-lg">
                <b-spinner  v-if="saving" class="mr-2"/>
                Trocar senha
              </button>
						</div>
					</form>
				</div>
				<!-- END login-content -->
			</div>
			<!-- END login-body -->
		</div>

		<!-- END login-container -->
	</div>
	<!-- END login -->
</template>

<script>
import AppOptions from '../../config/AppOptions.vue'
import ImageLogo from '../../assets/Logo-Multi-Branco.png'
import axios from 'axios'

export default {
  data(){
    return{
      ImageLogo,
      saving: false,
      cpf: '',
      senha: ''
    }
  },
	created() {
		AppOptions.appEmpty = true;
	},
	beforeRouteLeave (to, from, next) {
		AppOptions.appEmpty = false;
		next();
	},
	methods: {
	async	checkForm() {
    this.saving = true
    await axios
        .put(`usuario/${this.$route.params.id}/alterar-senha`, {
          'senha': this.senha
        })
        .then(res =>{
          return res.data.dados
        })
        .then(() =>{
          this.saving = false
          this.$notify({
            group: 'top-center',
            type: 'success',
            title: 'Senha alterada com sucesso!',
            text: `Efetue o login novamente!`
          });
          location.href = "/app/dashboard";

        })
        .catch(res =>{
          this.saving = false
          this.$notify({
            group: 'top-center',
            type: 'error',
            title: 'Não foi possivel alterar a senha!',
            text: `${res.response.data.mensagem}`
          });
        })

		}
	}
}
</script>